.Moon {
  margin-top: -25%;
  margin-right: -30%;
  height: 150px;

  @include media-breakpoint-up(md) {
    margin-right: 0%;
    height: 200px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 0%;
    margin-right: 0%;
    height: auto;
  }

  &__img {
    opacity: 0;
    transform: scale(0.25);
    transition: all 0.66s ease-in-out;

    &.active {
      opacity: 1;
      transform: scale(0.5);

      @include media-breakpoint-up(md) {
        transform: scale(0.75);
      }

      @include media-breakpoint-up(lg) {
        transform: scale(1);
      }
    }
  }
}
