body {
  color: $font-color-global;
  font-family: $font-family;
  font-size: 16px;
  width: 100%;
}

h2 {
  font-size: 1rem;
  font-weight: $font-weight-bold;
  padding-bottom: 0.25rem;

  @include media-breakpoint-up(md) {
    font-size: 1.25rem;
  }
}

p,
span {
  display: block;
  font-size: 1rem;
  padding-bottom: 0.15rem;

  @include xsDevice(0.7);
}

input[type="text"],
textarea {
  border: 0;
}

// Bootstrap overrides
.navbar-light .navbar-toggler {
  border: 0;
  padding: 0;
}

.navbar-collapse {
  flex-grow: initial;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}
