@mixin activeSectionNav($color) {
  span {
    background-color: $color;
  }
}

@mixin backgroundStyles() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $background-z-index;
}

@mixin boxStyles() {
  background-color: rgba($white, 0.5);
  border-radius: 0.5rem;
  font-size: 1rem;
  padding: 1rem;
}

@mixin boxSize() {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 80%;
  }
}

@mixin sectionStyles() {
  height: 100%;
  min-height: $min-section-height-mobile;
  overflow: hidden;
  position: relative;
  padding: 50px 0;
  @include media-breakpoint-up(md) {
    min-height: $min-section-height-desktop;
  }
  @media only screen and (orientation: portrait) {
    min-height: 100vh !important;
  }
}

@mixin xsDevice($fontSize) {
  @media only screen and (max-device-width: 374px) {
    font-size: $fontSize;
  }
}

@mixin xsPaddingBottom() {
  @media only screen and (max-width: 375px) and (max-height: 667px) and (orientation: portrait) {
    padding-bottom: 200px;
  }
}

@mixin portraitMargin() {
  @media only screen and (min-device-width: 375px) and (orientation: portrait) {
    margin-top: 33%;
  }
}
