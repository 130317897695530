.Section1 {
  $margin-top-desktop: 80px;

  @include sectionStyles();

  &__background {
    @include backgroundStyles();
    background: linear-gradient($dark-purple, $dark-purple-pink);
  }

  &__container {
    @include media-breakpoint-up(lg) {
      margin-top: -$margin-top-desktop;
    }
  }

  &__column {
    @include media-breakpoint-up(lg) {
      min-height: $min-section-height-desktop;
    }
  }

  &__row {
    @include portraitMargin();
  }

  &__content {
    @include boxSize();
    margin-top: 3rem;

    @include media-breakpoint-up(lg) {
      max-width: auto;
    }
  }

  &__field {
    @include boxSize();
    margin-top: 1rem;

    @include media-breakpoint-up(lg) {
      margin-top: 15%;
      margin-left: 20%;
    }
  }
}
