.Section7 {
  @include sectionStyles();
  @include xsPaddingBottom();

  &__background {
    @include backgroundStyles();
    background: linear-gradient($dark-blue, $dark-blue-purple);

    &-wrap {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  &__column {
    @include portraitMargin();
    @include media-breakpoint-up(lg) {
      min-height: $min-section-height-desktop;
    }
  }

  &__field {
    @include boxSize();
    margin-top: 1rem;
  }

  &__footer {
    color: $white;
    font-size: 0.5rem;
    text-align: center;
    position: absolute;
    bottom: 0;
  }

  a,
  a:hover {
    color: $dark-purple;
  }
}
