// Fade In
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// Pulse
@keyframes pulse {
  0% {
    transform: rotate(0deg) scale(1);
  }
  10% {
    transform: rotate(0deg) scale(1.2);
  }
  30% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(30deg) scale(1.2);
  }
  60% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}

// Plane Left
@keyframes planeLeft {
  0% {
    left: -500px;
  }
  100% {
    left: 120%;
  }
}

// Rocket Left
@keyframes rocketLeft {
  0% {
    bottom: 30%;
    left: -50%;
    transform: rotate(20deg) scale(1);
  }
  10% {
    bottom: 35%;
    transform: rotate(20deg) scale(1.5);
  }
  40% {
    left: 30%;
    transform: rotate(20deg) scale(1);
  }
  80% {
    bottom: 80%;
    transform: rotate(30deg) scale(0.5);
  }
  100% {
    bottom: 90%;
    left: 300%;
    transform: rotate(30deg) scale(0.1);
  }
}

// Balloon Right
@keyframes balloonRight {
  0% {
    bottom: -50%;
    right: -50%;
    transform: rotate(10deg) scale(0.5);
  }
  10% {
    bottom: 20%;
    transform: rotate(-10deg) scale(1);
  }
  40% {
    right: 50%;
    transform: rotate(10deg) scale(1.5);
  }
  80% {
    bottom: 50%;
    transform: rotate(-10deg) scale(1);
  }
  100% {
    bottom: 70%;
    right: 200%;
    transform: rotate(10deg) scale(0);
  }
}

// Four Frame Sprite
@keyframes fourFrameAnimation {
  100% {
    left: -400%;
  }
}

// Three Frame Sprite
@keyframes threeFrameAnimation {
  100% {
    left: -300%;
  }
}

// Two Frame sprite
@keyframes twoFrameAnimation {
  100% {
    left: -200%;
  }
}

// Animate from Left
@keyframes inFromLeft {
  0% {
    left: -800px;
  }
  100% {
    left: 120%;
  }
}

// Animate from Right
@keyframes inFromRight {
  0% {
    right: -500px;
  }
  100% {
    right: 120%;
  }
}

// In From Left Flip
@keyframes moveFromLeftFlip {
  0% {
    right: -100%;
    transform: scaleX(1);
  }
  20% {
    right: 0%;
    transform: scaleX(1);
  }
  40% {
    right: 25%;
    transform: scaleX(1);
  }
  59% {
    transform: scaleX(1);
  }
  60% {
    right: 50%;
    transform: scaleX(-1);
  }
  90% {
    right: -300%;
    transform: scaleX(-1);
  }
  100% {
    right: -300%;
    transform: scaleX(-1);
  }
}
