.Filter {
  @include boxStyles();
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  font-size: 0.75rem;
  text-align: right;

  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }

  span > span:first-of-type {
    color: $gray-300;
    font-weight: $font-weight-bold;
    margin-right: 1rem;
  }

  &__wrap {
    margin-left: auto;
    max-width: 90%;

    @include media-breakpoint-up(lg) {
      max-width: 80%;
    }
  }

  .Button {
    margin-right: 0;
    margin-left: 0.75rem;
  }
}
