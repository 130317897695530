.Headshot {
  position: absolute;
  top: -35px;
  right: -5px;
  transition: all 0.25s linear;
  width: 0px;

  &.active {
    margin-left: -50%;
    top: -70px;
    right: -10px;
    width: 100px;
  }

  @include media-breakpoint-up(md) {
    top: -45px;
    right: -45px;

    &.active {
      top: -100px;
      right: -70px;
      width: 150px;
    }
  }

  &__img {
    width: 100%;
  }
}
