.Contact {
  @include boxStyles();
  @include boxSize();
  position: relative;

  @include media-breakpoint-up(lg) {
    width: 60%;
  }

  span {
    display: initial;
  }

  span > span:first-of-type {
    color: $gray-300;
    font-weight: $font-weight-bold;
    margin-right: 1rem;
  }

  &__thank-you {
    background-color: $white;
    border-radius: 0.5rem;
    display: none;
    padding: 3rem;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;

    .Button {
      margin: 0 auto;
      width: 200px;

      @include media-breakpoint-up(md) {
        width: 300px;
      }
    }
  }

  &__block {
    padding-bottom: 0.5rem;
  }

  &__field {
    display: inline-block;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    max-width: 220px;

    input {
      width: 100%;
    }
  }

  &__textarea {
    border: 0;
    border-radius: 0.5rem;
    display: block;
    resize: none;
    padding: 1rem;
    width: 100%;
  }

  &__buttons {
    margin-top: 2rem;
    width: 100%;

    .Button {
      margin-bottom: 1rem;
      width: 100%;
    }
  }
}
