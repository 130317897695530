.Seaweed {
  position: absolute;
  bottom: 0;
  left: -5%;
  width: 150px;

  @include media-breakpoint-up(md) {
    width: 250px;
  }

  @include media-breakpoint-up(lg) {
    left: 15%;
  }

  &__img {
    width: 100%;
  }
}
