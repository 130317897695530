.Button {
  background-color: $white;
  border: 0;
  border-radius: 0.25rem;
  margin-right: 0.75rem;
  width: auto;

  &:last-of-type {
    margin-right: 0;
  }

  &__primary {
    color: $gray-300;
    margin-bottom: 0.25rem;
    padding: 0.1rem 0.5rem;

    &--selected {
      color: $dark-purple;
    }
  }

  &__light {
    background-color: $white;
    color: $dark-blue;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: $dark-purple;
    }
  }

  &__dark {
    background-color: $dark-blue;
    color: $white;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: $dark-purple;
    }
  }
}
