.FieldBox {
  @include boxStyles();

  width: 100%;
  padding-bottom: 1.5rem;
  position: relative;

  @include media-breakpoint-up(lg) {
    min-width: 16rem;
  }

  &__text {
    font-size: 1rem;
    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }
  }

  &__field {
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    width: 100%;

    @include media-breakpoint-up(lg) {
      bottom: -1.25rem;
      left: 33%;
      width: auto;
    }
  }
}
