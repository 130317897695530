.Plane {
  position: absolute;
  top: 3%;
  left: -500px;
  transition: all 15s linear;
  width: 500px;

  @media only screen and (orientation: portrait) {
    @include media-breakpoint-up(md) {
      top: 8%;
    }
  }

  &.active {
    animation: planeLeft 15s linear forwards;
  }

  &__img {
    width: 100%;
  }
}
