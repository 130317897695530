.Navigation {
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: $navigation-z-index;

  @include media-breakpoint-up(lg) {
    height: 80px;
  }
  @for $i from 1 through 7 {
    &--section#{$i} {
      .Navigation__bar {
        .Navigation__close-icon {
          @include activeSectionNav(nth($sectionColors, $i));
        }
      }

      .Navigation__link--home {
        &:hover {
          color: nth($sectionColors, $i);
        }
      }

      .Navigation__menu-item {
        &:hover .Navigation__link {
          color: nth($sectionColors, $i);
        }
      }
    }
  }

  &__bar {
    justify-content: flex-end;
    padding: 0.75rem 0;
  }

  &__links {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    text-align: right;

    @include media-breakpoint-down(md) {
      padding: 1rem 2rem;
    }

    @include media-breakpoint-up(lg) {
      display: block;
      position: relative;
      width: 100%;
    }

    &-wrap {
      padding: 1rem 0;
      position: relative;
    }

    &--open {
      background-color: $white;
      display: block;
    }
  }

  &__link {
    color: $gray-300;
    display: block;
    font-size: 2rem;
    transition: all 0.2s ease-in-out;

    @include media-breakpoint-up(lg) {
      font-size: 1.25rem;
    }

    &:hover,
    &:focus {
      cursor: pointer;
      text-decoration: none;
    }

    &--home {
      font-size: 1.25rem;
      padding: 0.66rem 0;

      @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
        margin-top: 0.5rem;
        padding: 1rem 0;
      }
    }
  }

  &__menu {
    list-style: none;
    margin: 0;
    padding: 0;

    &-item {
      display: block;
      margin: 0 0.75rem;

      @include media-breakpoint-up(lg) {
        display: inline-block;
      }
    }
  }

  &__close-icon {
    span {
      background-color: $gray-300;
      background-image: none !important;
      display: block;
      margin: 0.33rem 0;
      transition: all 0.2s ease-in-out;
      width: 30px;
      height: 0.175rem;
    }

    &--open {
      span:nth-child(1) {
        transform: translateY(0.5rem) rotate(-135deg);
      }

      span:nth-child(2) {
        transform: scale(0);
      }

      span:nth-child(3) {
        transform: translateY(-0.5rem) rotate(135deg);
      }
    }
  }
}
