@import "bootstrap/scss/bootstrap.scss";
@import "./variables.scss";
@import "./mixins.scss";
@import "./animations.scss";
@import "./global.scss";

// Components
@import "../../components/Balloon/Balloon.scss";
@import "../../components/Bass/Bass.scss";
@import "../../components/Birds/Birds.scss";
@import "../../components/Button/Button.scss";
@import "../../components/Clouds/Clouds.scss";
@import "../../components/Contact/Contact.scss";
@import "../../components/ContentBox/ContentBox.scss";
@import "../../components/Field/Field.scss";
@import "../../components/FieldBox/FieldBox.scss";
@import "../../components/Filter/Filter.scss";
@import "../../components/Fish/Fish.scss";
@import "../../components/FilterButton/FilterButton.scss";
@import "../../components/Headshot/Headshot.scss";
@import "../../components/Hills/Hills.scss";
@import "../../components/Links/Links.scss";
@import "../../components/LogoGitHub/LogoGitHub.scss";
@import "../../components/LogoLinkedIn/LogoLinkedIn.scss";
@import "../../components/Logos/Logos.scss";
@import "../../components/Moon/Moon.scss";
@import "../../components/Navigation/Navigation.scss";
@import "../../components/Plane/Plane.scss";
@import "../../components/Rocket/Rocket.scss";
@import "../../components/SkillBox/SkillBox.scss";
@import "../../components/SkillButton/SkillButton.scss";
@import "../../components/Seaweed/Seaweed.scss";
@import "../../components/Seaweed2/Seaweed2.scss";
@import "../../components/Seaweed3/Seaweed3.scss";
@import "../../components/Seaweed4/Seaweed4.scss";
@import "../../components/Sign/Sign.scss";
@import "../../components/Submarine/Submarine.scss";
@import "../../components/Sun/Sun.scss";
@import "../../components/Trout/Trout.scss";
@import "../../components/Turtle/Turtle.scss";

// Sections
@import "../../sections/Section1/Section1.scss";
@import "../../sections/Section2/Section2.scss";
@import "../../sections/Section3/Section3.scss";
@import "../../sections/Section4/Section4.scss";
@import "../../sections/Section5/Section5.scss";
@import "../../sections/Section6/Section6.scss";
@import "../../sections/Section7/Section7.scss";

// Pages
@import "../../pages/Main/Main.scss";
