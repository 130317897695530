.Seaweed2 {
  position: absolute;
  bottom: 0;
  right: -5%;
  width: 200px;

  @include media-breakpoint-up(md) {
    width: 300px;
  }

  @include media-breakpoint-up(lg) {
    right: 15%;
  }

  &__img {
    width: 100%;
  }
}
