.LogoGitHub {
  margin-top: 1rem;
  margin-right: 1rem;
  width: 80px;
  height: 80px;

  @include media-breakpoint-up(md) {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
