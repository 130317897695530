.Section4 {
  @include sectionStyles();
  @include xsPaddingBottom();

  &__background {
    @include backgroundStyles();
    background: linear-gradient($dark-orange, $dark-gold);

    .Clouds {
      max-width: 450px;
      left: auto;
      right: -700px;

      @include media-breakpoint-up(md) {
        max-width: 600px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 700px;
      }

      &.active {
        right: -175px;
      }
    }
  }

  &__column {
    @include media-breakpoint-up(lg) {
      min-height: $min-section-height-desktop;
    }
  }

  &__content {
    @include boxSize();
    @include portraitMargin();
    margin-top: 0%;
    margin-bottom: auto;

    @include media-breakpoint-up(md) {
      margin-top: 30%;
      margin-bottom: auto;
    }

    @include media-breakpoint-up(lg) {
      margin-top: auto;
      margin-bottom: 50%;
      max-width: 90%;
    }
  }

  &__field {
    @include boxSize();
    margin-top: 1rem;
    margin-bottom: auto;

    @include media-breakpoint-up(lg) {
      margin-bottom: 10%;
    }
  }

  .ContentBox {
    span {
      display: inline-block;
      margin-right: 1rem;

      &:first-of-type {
        display: block;
      }
    }
  }
}
