.Section3 {
  @include sectionStyles();

  &__background {
    @include backgroundStyles();
    background: linear-gradient($dark-pink, $dark-orange);
  }

  &__content {
    margin-top: 33%;
    width: auto;

    @include media-breakpoint-up(md) {
      width: 80%;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 12%;
      width: 65%;
    }

    @media only screen and (min-device-width: 1024px) and (orientation: portrait) {
      margin-top: 45%;
    }
  }
}
