.SkillBox {
  @include boxStyles();
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 0.75rem;
  min-height: 312px;

  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }

  span > span:first-of-type {
    color: $gray-300;
    font-weight: $font-weight-bold;
    margin-right: 1rem;
  }
}
