@import url("//fonts.googleapis.com/css?family=B612:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("//fonts.googleapis.com/css?family=Vollkorn:ital,wght@0,400;0,700;1,400;1,700&display=swap");

// Colors
$gray-300: #333;
$gray-600: #666;
$gray-900: #999;
$gray-bbb: #bbb;
$gray-ccc: #ccc;
$gray-ddd: #ddd;

$dark-blue: #1a81ff;
$dark-blue-purple: #324aff;
$dark-gold: #ff9f04;
$dark-green: #1af7ff;
$dark-orange: #ff7044;
$dark-purple: #7123ff;
$dark-purple-pink: #a821ff;
$dark-pink: #fc2ee1;
$dark-yellow: #ffc104;

$light-blue: #1ab1ff;

$white: #fff;
$black: #000;

// Fonts
$font-family: "B612", sans-serif;
$font-weight-heavy: 700;
$font-weight-normal: 500;
$font-weight-light: 300;

// Text Styles
$font-color-global: rgba($black, 0.8);
$text-shadow: rgba($black, 0.8);

// z-indexes
$background-z-index: 0;
$cloud-z-index: 1;
$navigation-z-index: 3;

// Layout
$min-section-height-mobile: 500px;
$min-section-height-desktop: 700px;

// Section Colors
$sectionColors: $dark-purple $dark-purple-pink $dark-pink $dark-orange
  $dark-gold $dark-blue $dark-blue-purple;
